import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { Link } from '@allthingswww/client-act-shared';
import Layout from '../components/Layout';
import ContentMdx from '../components/ContentMdxs';
import titleIcon from '../images/headerIcon.png';
import ContactFormNetlify from '../components/ContactForm';

const StyledContainer = styled.div`
  display: flex;
  padding: 2rem 0;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    flex-flow: column;
}
`;

const StyledLeftSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: 200px;

  flex-basis: 50%;
  @media only screen and (max-width: 600px) {
    margin: 20px auto;
}
`;

const StyledRightSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const StyledContactInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 12px;
`;

export const ContactUsPageMdx = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const [contactFormData, setContactFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });
  const { frontmatter, body } = data.page;
  const {
    title,
    subtitle,
    featuredImage,
    telNumbers,
    faxNumbers,
    email,
  } = frontmatter;

  return (
    <Layout
      title={title}
      subtitle={subtitle}
      headerBackGroundImage={featuredImage}
      condensed
      icon={titleIcon}
      meta={{ data, location }}
    >
      <StyledContainer>
        <StyledLeftSection>
          <ContentMdx source={body} />
          <StyledContactInfo>
            <div>
              Tel :
              {' '}
              {telNumbers.map((tel) => (
                <Link key={tel} to={`tel:${tel}`}>
                  {tel}
                </Link>
              ))}
            </div>
          </StyledContactInfo>

          <StyledContactInfo>
            <div>
              Fax :
              {' '}
              {faxNumbers.map((tel) => (
                <Link key={tel} to={`tel:${tel}`}>
                  {tel}
                </Link>
              ))}
            </div>
          </StyledContactInfo>

          <StyledContactInfo>
            <div>
              {email.map((mail) => (
                <Link key={mail} to={`mailto:${mail}`}>
                  {mail}
                </Link>
              ))}
            </div>
          </StyledContactInfo>
        </StyledLeftSection>
        <StyledRightSection>
          <ContactFormNetlify />
        </StyledRightSection>
      </StyledContainer>
    </Layout>
  );
};

export default ContactUsPageMdx;

export const pageQuery = graphql`
  query ContactUsPageMdx($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        subtitle
        featuredImage
        telNumbers
        faxNumbers
        email
      }
    }
  }
`;
