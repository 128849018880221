import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Input,
  TextArea,
  Button as DefaultButton,
} from '@allthingswww/client-act-shared';
import { Link } from 'gatsby';

const StyledDiv = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-flow: column nowrap;
  & > p {
    margin-top: 0.5rem;
    font-size: 11px;
    font-family: 'Montserrat';
    font-weight: 300;
    margin-left: 3px;
  }
`;

const Respect = styled.div`
  margin-top: 0.75rem;
  font-size: 11px;
  font-family: 'Montserrat';
  font-weight: 300;
  margin-left: 3px;
`;
const StyledMainContainer = styled.div`
  span {
    text-decoration: underline;
  }
`;

const ContactFormNetlify = () => {
  const [contactFormData, setContactFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });
  const [areAllRequiredFieldsFilled, setAreAllRequiredFieldsFilled] = useState(
    false,
  );
  function handleChange({ target }) {
    const { value, name } = target;
    setContactFormData({
      ...contactFormData,
      [name]: value,
    });
  }

  useEffect(() => {
    if (
      contactFormData.email.length === 0
      || contactFormData.fullName.length === 0
      || contactFormData.message.length === 0
      || !contactFormData.email.includes('@')
    ) {
      setAreAllRequiredFieldsFilled(false);
    } else {
      setAreAllRequiredFieldsFilled(true);
    }
  }, [contactFormData]);

  return (
    <StyledMainContainer>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value="contact" />
        <Input
          value={contactFormData.fullName}
          handleChange={handleChange}
          type="text"
          name="fullName"
          placeholder="Full name*"
          isRequired
        />
        <Input
          value={contactFormData.email}
          handleChange={handleChange}
          type="email"
          name="email"
          placeholder="Email address*"
          isRequired
        />
        <TextArea
          value={contactFormData.message}
          handleChange={handleChange}
          name="message"
          maxLength={5000}
          placeholder="Your message*"
        />

        <StyledDiv>
          <DefaultButton.Button
            variant
            type="submit"
            isDisabled={!areAllRequiredFieldsFilled}
          >
            Send Message
          </DefaultButton.Button>
          <Respect>
            <Link to="/privacy">
              <span>We respect your privacy</span>
            </Link>
          </Respect>
        </StyledDiv>
      </form>
    </StyledMainContainer>
  );
};

export default ContactFormNetlify;
